import React, {createContext, Dispatch, lazy, SetStateAction, Suspense, useEffect, useState, useCallback} from 'react';

// @ts-ignore
import {BooksHooks, ModalSpinner, NotFound, openSpinner, closeSpinner} from '@paytheory/pay-theory-ui';

import {generateMenu, getOnboardingStatus} from './views/util';

import {ErrorMessage, SuccessMessage} from '@paytheory/components.common.portal_head';
import AdminPortal from "./components/AdminPortal";

import './App.css';

import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import {withAuthenticator} from '@aws-amplify/ui-react';
import {defaultOnboardingState} from './views/Onboarding/model';

import * as network from './network';
import * as Login from './components/LoginComponents';
import * as ROUTES from './constants/routes';
import {useUserTimeout} from './views/util';

import {MerchantOnboarding} from "./GraphQL/internal_types";
import {OnboardingState} from "./components/OnboardingCard/model";
import {AmplifyUser} from "@aws-amplify/ui";
import {Merchant} from "./GraphQL/backoffice";


// @ts-ignore
const Font = lazy(() => import ('@paytheory/pay-theory-ui/dist/Font'));

// const Unauthorized = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

// const NotFound = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

const ManageAdmins = lazy(() => import ('./views/ManageAdmins'));

const ManageMerchants = lazy(() => import ('./views/ManageMerchants'));

const MerchantDetail = lazy(() => import ('./views/MerchantDetail'));

const Settings = lazy(() => import ('./views/Settings'));

const Onboarding = lazy(() => import ('./views/Onboarding'));

type AppContextType = {
    signOut: () => void;
    merchantUID: string;
    merchant: Merchant | null;
    setMerchant: Dispatch<SetStateAction<Merchant | null>>;
    getMerchant: (merchant_uid: string, failure_action?: () => void) => void;
    ErrorMessage: (message: any, stay?: boolean) => void;
    SuccessMessage: (message: any, stay?: boolean) => void;
    onboardingData: MerchantOnboarding | null;
    setOnboardingData: Dispatch<SetStateAction<MerchantOnboarding | null>>;
    getOnboardingData: (merchantUid: string) => (action?: (data: typeof defaultOnboardingState) => void) => void;
    onboardingStatus: OnboardingState;
}

export const AppContext = createContext<AppContextType>({} as AppContextType);

type AppProps = {
    signOut: () => void;
    user: AmplifyUser;
}

export const App = (props: AppProps) => {
    const {signOut, user} = props;
    const [merchantUID, setMerchantUID] = useState<string>("");
    const [merchant, setMerchant] = useState<null | Merchant>(null);
    const [onboardingData, setOnboardingData] = useState<MerchantOnboarding | null>(null);
    const onboardingStatus = getOnboardingStatus(merchant, onboardingData)
    const navigate = useNavigate();


    const getOnboardingData = useCallback((merchant_uid: string) => (action?: (data: typeof defaultOnboardingState) => void) => {
        network.onboarding.get(merchant_uid)
            .then(response => {
                const copy = JSON.parse(JSON.stringify(defaultOnboardingState))
                copy.merchant_uid = merchant_uid
                const newOnboardingData = response.data?.merchantOnboarding || copy
                setOnboardingData(newOnboardingData)
                if (action) action(newOnboardingData)
            })
            .catch(err => {
                console.log(err)
            })
    }, [defaultOnboardingState]);

    const getMerchant = useCallback((uid: string, afterFetch?: (merchant?: Merchant) => void) => {
        network.merchants.query(uid)
            .then(response => {
                let fetchedMerchant = response?.data?.merchant!
                if(onboardingData && onboardingData.merchant_uid !== uid) setOnboardingData(null)
                setMerchant(fetchedMerchant)
                if (afterFetch) afterFetch(response?.data?.merchant!)
                if ((!onboardingData || onboardingData.merchant_uid !== uid) && (!fetchedMerchant?.ach_active || !fetchedMerchant?.card_active || !fetchedMerchant?.cash_active)) {
                    getOnboardingData(uid)()
                }
                closeSpinner()
            })
            .catch(err => {
                console.log(err)
                navigate(ROUTES.MANAGE_MERCHANTS, {state: {error: "Merchant not found."}})
                if (afterFetch) afterFetch()
            })
    }, [onboardingData, getOnboardingData]);


    const typekit = process.env.REACT_APP_TYPEKIT;

    useEffect(() => {
        if (user) {
            const signInUserSession = user.getSignInUserSession()
            let merchant = signInUserSession?.getIdToken().payload['custom:merchant_uid'];
            setMerchantUID(merchant);
        }
    }, [user]);

    // Set up a timeout to log the user out if they are inactive for a set amount of time
    useUserTimeout(user, signOut);

    return (
        <div id="container">
            <div className="spinner-wrapper">
                <div className="modal-wrapper">
                    <BooksHooks.Context.Menu.Provider value={generateMenu()}>
                        <AppContext.Provider value={{
                            merchantUID,
                            signOut,
                            ErrorMessage,
                            SuccessMessage,
                            merchant,
                            setMerchant,
                            getMerchant,
                            onboardingData,
                            setOnboardingData,
                            getOnboardingData: getOnboardingData,
                            onboardingStatus
                        }}>
                            <Suspense fallback={<ModalSpinner on/>}>
                                <Routes>
                                    <Route
                                        path={ROUTES.ONBOARDING}
                                        element={<Onboarding/>}
                                    />
                                    <Route path={"/"} element={<AdminPortal paged={{}}
                                                                            generateMenu={generateMenu}
                                                                            logout={signOut}/>}>
                                    <Route path="/" element={<Navigate replace to={ROUTES.HOME}/>}/>
                                    <Route
                                        path={ROUTES.MANAGE_ADMINS}
                                        element={<ManageAdmins/>}
                                    />
                                    <Route
                                        path={ROUTES.MANAGE_MERCHANTS}
                                        element={<ManageMerchants/>}
                                    />
                                    <Route
                                        path={ROUTES.MERCHANT_DETAIL}
                                        element={<MerchantDetail />}
                                    />
                                    <Route
                                        path={ROUTES.SETTINGS}
                                        element={<Settings/>}
                                    />
                                    <Route
                                        path="*"
                                        element={<Navigate replace to={ROUTES.HOME}/>}
                                    />
                                    </Route>
                                </Routes>
                                <Font typekit={typekit}/>
                            </Suspense>
                            <ModalSpinner/>
                        </AppContext.Provider>
                    </BooksHooks.Context.Menu.Provider>
                </div>
            </div>
        </div>);
};

export default withAuthenticator(App, {
    loginMechanisms: ['email'],
    components: {
        Header: Login.Header,
        SignIn: {
            Header: Login.SignInHeader
        },
    }
});

import {appSyncQuery} from "./index";

const listString = `{
  nativeApps {
    app_id
    description
    device_check_key
    device_check_kid
    merchant_uid
    platform
  }
}
`

export const list = () => {
    return appSyncQuery(listString, {}, true)
}

const createString = `mutation CreateNativeApp($platform: NativeAppPlatform!, $merchant_uid: String!, $device_check_kid: String, $device_check_key: String, $description: String $app_id: String!) {
  createNativeApp(input: {app_id: $app_id, description: $description, device_check_key: $device_check_key, device_check_kid: $device_check_kid, platform: $platform, merchant_uid: $merchant_uid})
}`

export const create = (variables) => {
    const parsedVariables = {
        platform: variables.platform,
        merchant_uid: variables.merchant_uid,
        device_check_kid: variables.device_check_kid,
        device_check_key: variables.device_check_key,
        description: variables.description,
        app_id: variables.app_id
    }
    return appSyncQuery(createString, parsedVariables, true)
}

const updateString = `mutation UpdateNativeApp($platform: NativeAppPlatform!, $merchant_uid: String!, $device_check_kid: String, $device_check_key: String, $description: String, $app_id: String!) {
  updateNativeApp(input: {app_id: $app_id, description: $description, device_check_key: $device_check_key, device_check_kid: $device_check_kid, platform: $platform, merchant_uid: $merchant_uid })
}`

export const update = (variables) => {
    const parsedVariables = {
        platform: variables.platform,
        merchant_uid: variables.merchant_uid,
        device_check_kid: variables.device_check_kid,
        device_check_key: variables.device_check_key,
        description: variables.description,
        app_id: variables.app_id
    }
    return appSyncQuery(updateString, parsedVariables, true)
}

const deleteString = `mutation MyMutation($app_id: String!, $merchant_uid: String!) {
  deleteNativeApp(app_id: $app_id, merchant_uid: $merchant_uid)
}`

export const deleteApp = (merchant_uid, app_id) => {
    const parsedVariables = {
        merchant_uid: merchant_uid,
        app_id: app_id
    }
    return appSyncQuery(deleteString, parsedVariables, true)
}
//authentication
export const LANDING = '/'
export const ACCOUNT = '/account'

//administration
export const MANAGE_ADMINS = '/manage-administrators'
export const ADMIN_ACCOUNT_DETAIL = '/manage-administrators/account-detail/:key'

export const MANAGE_MERCHANTS = '/manage-merchants'
export const HOME = MANAGE_MERCHANTS
export const CREATE_MERCHANT = '/manage-merchants/create-merchant'
export const MERCHANT_DETAIL = '/manage-merchants/merchant-detail/:key'
export const MERCHANT_ACCOUNT_DETAIL =
    '/manage-merchants/merchant-detail/:merchant/merchant-account-detail/:key'
export const ONBOARDING = '/onboarding/:key'

export const PAYMENTS = '/transactions'
export const PAYMENT_DETAILS = '/transactions/:key'

//error
export const VERIFICATION_FAILED = '/verification-failed'
export const NOT_FOUND = '/not-found'
export const UNAUTHORIZED = '/unauthorized'

// settings
export const SETTINGS = '/settings'
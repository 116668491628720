export const MERCHANT_TABLE_RPP = 'PT_PARTNER_MERCHANT_TABLE_RPP';
export const FILTER_WARNING = 'PT_MERCHANT_FILTER_WARNING';
export const PAYMENT_TABLE_RPP = 'PT_MERCHANT_PAYMENT_TABLE_RPP';

export const getLocalStorage = (key: string) => (): string | null => {
    try {
        return localStorage?.getItem(key) ?? null
    } catch (e) {
        return null
    }
}

export const setLocalStorage = (key: string) => (value: string) => {
    try {
        localStorage?.setItem(key, value)
    } catch (e) {
        // do nothing
    }
}

export const removeLocalStorage = (key: string) => () => {
    try {
        localStorage?.removeItem(key)
    } catch (e) {
        // do nothing
    }
}

export const getFilterWarning = getLocalStorage(FILTER_WARNING)
export const setFilterWarning = setLocalStorage(FILTER_WARNING)

export const getPaymentTableRpp = getLocalStorage(PAYMENT_TABLE_RPP)
export const setPaymentTableRpp = setLocalStorage(PAYMENT_TABLE_RPP)

export const getMerchantTableRpp = getLocalStorage(MERCHANT_TABLE_RPP)
export const setMerchantTableRpp = setLocalStorage(MERCHANT_TABLE_RPP)






import { appSyncQuery } from './index'
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
    MerchantOnboarding,
    MerchantOnboardingInput,
    QueryMerchantOnboardingArgs,
    DocumentInput
} from "../GraphQL/internal_types";

const getString = `query GetOnboardingData($merchant_uid: ID!) {
  merchantOnboarding(merchant_uid: $merchant_uid) {
    needs_docs
    ticket_id
    user_email
    user_full_name
    merchant_uid
    bank {
      account_number
      account_type
      bank_code
      name
    }
    business {
      additional_underwriting_data {
        annual_ach_volume
        average_ach_transfer_amount
        average_card_transfer_amount
        business_description
        card_volume_distribution {
          card_present_percentage
          ecommerce_percentage
        }
        credit_check_allowed
        credit_check_ip_address
        credit_check_timestamp
        credit_check_user_agent
        merchant_agreement_accepted
        merchant_agreement_ip_address
        merchant_agreement_timestamp
        merchant_agreement_user_agent
        refund_policy
        statement_descriptor
        volume_distribution_by_business_type {
          business_to_business_volume_percentage
          business_to_consumer_volume_percentage
          other_volume_percentage
        }
      }
      underwriting_data {
        annual_card_volume
        business_address {
          city
          country
          line1
          line2
          postal_code
          region
        }
        business_name
        business_type
        doing_business_as
        has_accepted_credit_cards_previously
        incorporation_date
        max_transaction_amount
        mcc
        ownership_type
        phone
        tax_id
        url
      }
    }
    business_owners {
      address {
        city
        country
        line1
        line2
        postal_code
        region
      }
      dob
      email
      first_name
      last_name
      middle_initial
      percentage_ownership
      phone
      primary_owner
      tax_id
      title
      uid
    }
    is_locked
  }
}`

export const get = (merchant_uid: string):  Promise<GraphQLResult<{merchantOnboarding: MerchantOnboarding}>> => {
    const variables: QueryMerchantOnboardingArgs = {
        merchant_uid
    }
    return appSyncQuery(getString, variables, true)
}

const updateString = `mutation UpdateOnboarding($is_form_completed: Boolean!, $merchant_onboarding: MerchantOnboardingInput!, $docs: [DocumentInput]) {
  createMerchantOnboarding(is_form_completed: $is_form_completed, merchant_onboarding: $merchant_onboarding, support_note: { support_docs: $docs })
}`

export const update = (merchant_onboarding: MerchantOnboardingInput, is_form_completed: boolean, docs: DocumentInput[] | null): Promise<GraphQLResult<{createMerchantOnboarding: boolean}>>  => {
    const variables = {
        merchant_onboarding,
        is_form_completed,
        docs
    }
    return appSyncQuery(updateString, variables, true)
}

const uploadOnboardingDocumentString = `mutation createSupportNote($merchant_uid: ID!, $ticket_id: ID!, $docs: [DocumentInput]!) {
  createSupportNote(merchant_uid: $merchant_uid, support_note: { support_docs: $docs }, ticket_id: $ticket_id)
}`

export const uploadOnboardingDocument = (merchant_uid: string, ticket_id: number, docs: DocumentInput[]): Promise<GraphQLResult<{createSupportNote: boolean}>> => {
    const variables = {
        merchant_uid,
        ticket_id: ticket_id.toString(),
        docs
    }
    return appSyncQuery(uploadOnboardingDocumentString, variables, true)
}
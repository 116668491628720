import {appSyncQuery} from './index';
import * as gql from "../constants/GraphQL";
import {Merchant, Merchants, MoveDirection, SortDirection} from "../GraphQL/backoffice";
import {GraphQLResult} from "@aws-amplify/api-graphql";

const listString = `query listMerchants($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $query: SqlQuery) {
  merchants(direction: $direction, 
            limit: $limit, 
            offset: $offset, 
            offset_id: $offset_id, 
            query: $query) {
    items {
      ach_active
      card_active
      cash_active
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
    }
    total_row_count
  }
}
`

export const list = (order: SortDirection, offset: Merchant | undefined | null, limit: number, filter: string, direction: MoveDirection): Promise<GraphQLResult<{merchants: Merchants}>> => {
  let sort = [{key: 'merchant_name', direction: order}]
  let formattedFilter = filter ? {key: 'merchant_name', value: `%${filter}%`, operator: gql.LIKE } : null
  const queryObject = {
    query_list: formattedFilter,
    sort_list: sort,
  }
  const variables = {
    query: queryObject,
    offset_id: offset?.merchant_uid,
    offset: offset?.merchant_name,
    limit: limit,
    direction: direction
  }
  return appSyncQuery(listString, variables)
}

const queryString = `query getMerchant($merchant_uid: String) {
  merchant(merchant_uid: $merchant_uid) {
    merchant_uid
    merchant_name
    api_key
    submitted_onboarding
    is_system
    parent_merchant_uid
    card_active
    ach_active
    cash_active
    fee_model {
      merchant_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee_min {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
    }
  }
}
`

export const query = (uid: string): Promise<GraphQLResult<{merchant: Merchant}>> => {
  const variables = {
    merchant_uid: uid
  }
  return appSyncQuery(queryString, variables)
}

const createString = `mutation CreateMerchant($parent_merchant_uid: String, $merchant_name: String!, $user: MerchantUserInput) {
  createMerchant(merchant_name: $merchant_name, parent_merchant_uid: $parent_merchant_uid, user: $user) {
    ach_active
    card_active
    cash_active
    merchant_name
    merchant_uid
    parent_merchant_uid
    submitted_onboarding
  }
}
`

export const create = (name: string, parent: string, firstName: string, lastName: string, email: string, phone: string) => {
    let user = null
    // Only pass a user object if at least one of the fields is filled out
    if (firstName || lastName || email || phone) {
        user = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone || null
        }
    }
  const variables = {
    merchant_name: name,
    parent_merchant_uid: parent,
    user
  }
  return appSyncQuery(createString, variables)
}

export const IN_LIST = "IN_LIST"
export const NOT_IN_LIST = "NOT_IN_LIST"
export const LIKE = "LIKE"
export const NOT_LIKE = "NOT_LIKE"
export const EQUAL = "EQUAL"
export const NOT_EQUAL = "NOT_EQUAL"
export const GREATER_THAN = "GREATER_THAN"
export const GREATER_EQUAL = "GREATER_EQUAL"
export const LESS_THAN = "LESS_THAN"
export const LESS_EQUAL = "LESS_EQUAL"
export const IS_NULL = "IS_NULL"
export const IS_NOT_NULL = "IS_NOT_NULL"

export const FORWARD = "FORWARD"
export const BACKWARD = "BACKWARD"

export const DESC = "DESC"
export const ASC = "ASC"

export const AND = "AND_NEXT"
export const OR = "OR_NEXT"
export const NONE = "NONE_NEXT"
export const EQUAL_TRUE = "EQUAL_TRUE"
export const EQUAL_FALSE = "EQUAL_FALSE"

export const ENUM_KEYS = ['transaction_type', 'status', 'dispute_status', 'payment_type']

const formatValue = (value, key) => {
    return `"${value}"`
}

export const formatQueryObject = (queryObjects) => {
    let result = "["
    queryObjects.forEach((queryObject) => {
        if (queryObject.value) {
            result += `{
      key: "${queryObject.key}",
      value: ${formatValue(queryObject.value, queryObject.key)},
      operator: ${queryObject.operator},
      conjunctive_operator: ${queryObject.conjunctive_operator || NONE}
    }`
        } else if (queryObject.query_group) {
            result += `{
      query_group: [`
            result +=  queryObject.query_group.map((queryObject) => {
                return formatQueryObject(queryObject)
            }).join(',')
            result += `],
      conjunctive_operator: ${queryObject.conjunctive_operator || NONE}
    }`
        } else if (queryObject.in_values) {
            result += `{
      key: "${queryObject.key}",
      in_values: [`
            result += queryObject.in_values.map((in_value) => {
                return formatValue(in_value, queryObject.key)
            }).join(',')
            result += `],
      operator: ${queryObject.operator},
      conjunctive_operator: ${queryObject.conjunctive_operator || NONE}
    }`
        }
    })
    return result + ']'
}
export const formatSortPair = (sortPairs) => {
    let result = "["
    result +=  sortPairs.map((sortPair) => {
        return `{
        key: "${sortPair.key}",
        direction: ${sortPair.direction}
        }`
    }).join(',')
    return result + ']'
}
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSIPAddress: { input: string; output: string; }
  AWSJSON: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTime: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
};

export type AcceptedPaymentMethods = {
  __typename?: 'AcceptedPaymentMethods';
  ach?: Maybe<Scalars['Boolean']['output']>;
  card?: Maybe<Scalars['Boolean']['output']>;
  cash?: Maybe<Scalars['Boolean']['output']>;
};

export enum AcceptedPaymentMethodsEnum {
  All = 'ALL',
  NotAch = 'NOT_ACH',
  NotCard = 'NOT_CARD',
  NotCash = 'NOT_CASH',
  OnlyAch = 'ONLY_ACH',
  OnlyCard = 'ONLY_CARD',
  OnlyCash = 'ONLY_CASH'
}

export type AcceptedPaymentMethodsInput = {
  ach?: InputMaybe<Scalars['Boolean']['input']>;
  card?: InputMaybe<Scalars['Boolean']['input']>;
  cash?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AchAccountType {
  BusinessChecking = 'BUSINESS_CHECKING',
  BusinessSavings = 'BUSINESS_SAVINGS',
  PersonalChecking = 'PERSONAL_CHECKING',
  PersonalSavings = 'PERSONAL_SAVINGS'
}

export type AchInput = {
  account_number: Scalars['String']['input'];
  account_type: AchAccountType;
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name_on_account: Scalars['String']['input'];
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  routing_number: Scalars['String']['input'];
};

export type AchReturnDetails = {
  __typename?: 'AchReturnDetails';
  return_code?: Maybe<Scalars['String']['output']>;
  return_details?: Maybe<Scalars['String']['output']>;
};

export type AdditionalPurchaseData = {
  __typename?: 'AdditionalPurchaseData';
  level3_data_line_item?: Maybe<Array<Maybe<Level3DataLineItem>>>;
  level3_data_summary?: Maybe<Level3DataSummary>;
};

export type AdditionalPurchaseDataInput = {
  level3_data_line_item?: InputMaybe<Array<InputMaybe<Level3DataLineItemInput>>>;
  level3_data_summary?: InputMaybe<Level3DataSummaryInput>;
};

export enum AuthProvider {
  ApiKey = 'apiKey',
  Iam = 'iam',
  Oidc = 'oidc',
  UserPools = 'userPools'
}

export type AuthRule = {
  allow: AuthStrategy;
  groupClaim?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupsField?: InputMaybe<Scalars['String']['input']>;
  identityClaim?: InputMaybe<Scalars['String']['input']>;
  mutations?: InputMaybe<Array<InputMaybe<ModelMutation>>>;
  operations?: InputMaybe<Array<InputMaybe<ModelOperation>>>;
  ownerField?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<AuthProvider>;
  queries?: InputMaybe<Array<InputMaybe<ModelQuery>>>;
};

export enum AuthStrategy {
  Groups = 'groups',
  Owner = 'owner',
  Private = 'private',
  Public = 'public'
}

export type Authorization = {
  __typename?: 'Authorization';
  account_code?: Maybe<Scalars['String']['output']>;
  additional_purchase_data?: Maybe<AdditionalPurchaseData>;
  amount: Scalars['Int']['output'];
  authorization_date: Scalars['AWSDateTime']['output'];
  authorization_id: Scalars['String']['output'];
  captured_amount?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  device_id?: Maybe<Scalars['String']['output']>;
  expiration_date?: Maybe<Scalars['AWSDateTime']['output']>;
  failure_reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fee_mode: FeeMode;
  fees: Scalars['Int']['output'];
  invoice?: Maybe<Invoice>;
  merchant_uid: Scalars['String']['output'];
  metadata?: Maybe<Scalars['AWSJSON']['output']>;
  payment_method: PaymentMethodToken;
  reference?: Maybe<Scalars['String']['output']>;
  sale_id?: Maybe<Scalars['String']['output']>;
  status: AuthorizationStatus;
  timezone?: Maybe<Scalars['String']['output']>;
  transaction_id?: Maybe<Scalars['String']['output']>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']['output']>;
};


export type AuthorizationMetadataArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type AuthorizationPayment_MethodArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export enum AuthorizationStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED'
}

export type Authorizations = {
  __typename?: 'Authorizations';
  items?: Maybe<Array<Maybe<Authorization>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export enum CallToActionType {
  Book = 'BOOK',
  Donate = 'DONATE',
  Pay = 'PAY'
}

export type CardInput = {
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  card_number: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  exp_date: Scalars['String']['input'];
  full_name?: InputMaybe<Scalars['String']['input']>;
  postal_code: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  security_code: Scalars['String']['input'];
};

export enum ConjunctiveOperator {
  AndNext = 'AND_NEXT',
  NoneNext = 'NONE_NEXT',
  OrNext = 'OR_NEXT'
}

export type Device = {
  __typename?: 'Device';
  device_description?: Maybe<Scalars['String']['output']>;
  device_id?: Maybe<Scalars['String']['output']>;
  device_name?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
};

export type Devices = {
  __typename?: 'Devices';
  items?: Maybe<Array<Maybe<Device>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export type DigitalWalletInput = {
  address_line1?: InputMaybe<Scalars['String']['input']>;
  dpan: Scalars['String']['input'];
  exp_date: Scalars['String']['input'];
  postal_code?: InputMaybe<Scalars['String']['input']>;
  wallet_type: WalletType;
};

export type Dispute = {
  __typename?: 'Dispute';
  amount?: Maybe<Scalars['Int']['output']>;
  dispute_date?: Maybe<Scalars['AWSDateTime']['output']>;
  dispute_id?: Maybe<Scalars['String']['output']>;
  evidence_last_send_date?: Maybe<Scalars['AWSDateTime']['output']>;
  expiration_date?: Maybe<Scalars['AWSDateTime']['output']>;
  merchant_uid?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<DisputeReason>;
  reason_message?: Maybe<Scalars['String']['output']>;
  settlement_deposit_batch?: Maybe<Scalars['String']['output']>;
  settlement_withdrawal_batch?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DisputeStatus>;
  transaction?: Maybe<Transaction>;
  updated_date?: Maybe<Scalars['AWSDateTime']['output']>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum DisputeReason {
  Clerical = 'CLERICAL',
  Fraud = 'FRAUD',
  Inquiry = 'INQUIRY',
  Quality = 'QUALITY',
  Technical = 'TECHNICAL'
}

export enum DisputeStatus {
  Inquiry = 'INQUIRY',
  Lost = 'LOST',
  Pending = 'PENDING',
  Won = 'WON'
}

export type Disputes = {
  __typename?: 'Disputes';
  items?: Maybe<Array<Maybe<Dispute>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export enum FeeMode {
  Interchange = 'INTERCHANGE',
  MerchantFee = 'MERCHANT_FEE',
  ServiceFee = 'SERVICE_FEE'
}

export type FeeModel = {
  __typename?: 'FeeModel';
  interchange_plus?: Maybe<Scalars['Boolean']['output']>;
  merchant_fee?: Maybe<FeeModelDetail>;
  service_fee?: Maybe<FeeModelDetail>;
  service_fee_min?: Maybe<FeeModelDetail>;
};

export type FeeModelDetail = {
  __typename?: 'FeeModelDetail';
  ach_basis?: Maybe<Scalars['Int']['output']>;
  ach_fixed?: Maybe<Scalars['Int']['output']>;
  card_basis?: Maybe<Scalars['Int']['output']>;
  card_fixed?: Maybe<Scalars['Int']['output']>;
};

export enum HealthExpenseType {
  Clinical = 'CLINICAL',
  Copay = 'COPAY',
  Dental = 'DENTAL',
  Healthcare = 'HEALTHCARE',
  Rx = 'RX',
  Transit = 'TRANSIT',
  Vision = 'VISION'
}

export type Invoice = {
  __typename?: 'Invoice';
  account_code?: Maybe<Scalars['String']['output']>;
  api_key?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['AWSDateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  due_by?: Maybe<Scalars['AWSDate']['output']>;
  fee_mode?: Maybe<FeeMode>;
  invoice_amount?: Maybe<Scalars['Int']['output']>;
  invoice_date?: Maybe<Scalars['AWSDate']['output']>;
  invoice_description?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  invoice_name?: Maybe<Scalars['String']['output']>;
  is_secure?: Maybe<Scalars['Boolean']['output']>;
  merchant_invoice_number?: Maybe<Scalars['String']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  offline_transactions?: Maybe<Array<Maybe<OfflineTransaction>>>;
  payor?: Maybe<Payor>;
  reference?: Maybe<Scalars['String']['output']>;
  require_payor_address?: Maybe<Scalars['Boolean']['output']>;
  security_pin?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<InvoiceSettings>;
  status?: Maybe<InvoiceStatus>;
  total_paid_amount?: Maybe<Scalars['Int']['output']>;
};


export type InvoicePayorArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export type InvoiceInput = {
  account_code?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  due_by?: InputMaybe<Scalars['AWSDate']['input']>;
  fee_mode?: InputMaybe<FeeMode>;
  invoice_amount: Scalars['Int']['input'];
  invoice_date?: InputMaybe<Scalars['AWSDate']['input']>;
  invoice_description?: InputMaybe<Scalars['String']['input']>;
  invoice_name?: InputMaybe<Scalars['String']['input']>;
  is_secure?: InputMaybe<Scalars['Boolean']['input']>;
  merchant_invoice_number?: InputMaybe<Scalars['String']['input']>;
  merchant_uid: Scalars['String']['input'];
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  require_payor_address?: InputMaybe<Scalars['Boolean']['input']>;
  send_email?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<InvoiceSettingsInput>;
};

export type InvoiceSettings = {
  __typename?: 'InvoiceSettings';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethods>;
};

export type InvoiceSettingsInput = {
  accepted_payment_methods?: InputMaybe<AcceptedPaymentMethodsInput>;
};

export enum InvoiceStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID'
}

export type InvoiceUpdateInput = {
  account_code?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  due_by?: InputMaybe<Scalars['AWSDate']['input']>;
  fee_mode?: InputMaybe<FeeMode>;
  invoice_amount?: InputMaybe<Scalars['Int']['input']>;
  invoice_date?: InputMaybe<Scalars['AWSDate']['input']>;
  invoice_description?: InputMaybe<Scalars['String']['input']>;
  invoice_name?: InputMaybe<Scalars['String']['input']>;
  is_secure?: InputMaybe<Scalars['Boolean']['input']>;
  merchant_invoice_number?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  require_payor_address?: InputMaybe<Scalars['Boolean']['input']>;
  send_email?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<InvoiceSettingsInput>;
};

export type InvoicesWithQuery = {
  __typename?: 'InvoicesWithQuery';
  items?: Maybe<Array<Maybe<Invoice>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export type Level3DataLineItem = {
  __typename?: 'Level3DataLineItem';
  item_code?: Maybe<Scalars['String']['output']>;
  item_description?: Maybe<Scalars['String']['output']>;
  item_qty_exp?: Maybe<Scalars['Int']['output']>;
  prod_code?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['Int']['output']>;
  tax_amount?: Maybe<Scalars['Int']['output']>;
  tax_ind?: Maybe<TaxIndicatorType>;
  tax_rate?: Maybe<Scalars['Int']['output']>;
  tax_rt_exp?: Maybe<Scalars['Int']['output']>;
  tax_type_id?: Maybe<TaxType>;
  unit_cost?: Maybe<Scalars['Int']['output']>;
  unit_of_msure?: Maybe<Scalars['String']['output']>;
};

export type Level3DataLineItemInput = {
  item_code?: InputMaybe<Scalars['String']['input']>;
  item_description?: InputMaybe<Scalars['String']['input']>;
  item_qty_exp?: InputMaybe<Scalars['Int']['input']>;
  prod_code?: InputMaybe<Scalars['String']['input']>;
  qty?: InputMaybe<Scalars['Int']['input']>;
  tax_amount?: InputMaybe<Scalars['Int']['input']>;
  tax_ind?: InputMaybe<TaxIndicatorType>;
  tax_rate?: InputMaybe<Scalars['Int']['input']>;
  tax_rt_exp?: InputMaybe<Scalars['Int']['input']>;
  tax_type_id?: InputMaybe<TaxType>;
  unit_cost?: InputMaybe<Scalars['Int']['input']>;
  unit_of_msure?: InputMaybe<Scalars['String']['input']>;
};

export type Level3DataSummary = {
  __typename?: 'Level3DataSummary';
  dest_postal_code?: Maybe<Scalars['String']['output']>;
  discnt_amt?: Maybe<Scalars['Int']['output']>;
  duty_amt?: Maybe<Scalars['Int']['output']>;
  frght_amt?: Maybe<Scalars['Int']['output']>;
  order_num?: Maybe<Scalars['String']['output']>;
  prod_desc?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  purch_idfr?: Maybe<Scalars['String']['output']>;
  tax_amt?: Maybe<Scalars['Int']['output']>;
  tax_ind?: Maybe<TaxIndicatorType>;
};

export type Level3DataSummaryInput = {
  dest_postal_code?: InputMaybe<Scalars['String']['input']>;
  discnt_amt?: InputMaybe<Scalars['Int']['input']>;
  duty_amt?: InputMaybe<Scalars['Int']['input']>;
  frght_amt?: InputMaybe<Scalars['Int']['input']>;
  order_num?: InputMaybe<Scalars['String']['input']>;
  prod_desc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  purch_idfr?: InputMaybe<Scalars['String']['input']>;
  tax_amt?: InputMaybe<Scalars['Int']['input']>;
  tax_ind?: InputMaybe<TaxIndicatorType>;
};

export type ListMerchant = {
  __typename?: 'ListMerchant';
  ach_active?: Maybe<Scalars['Boolean']['output']>;
  card_active?: Maybe<Scalars['Boolean']['output']>;
  cash_active?: Maybe<Scalars['Boolean']['output']>;
  is_system?: Maybe<Scalars['Boolean']['output']>;
  merchant_name?: Maybe<Scalars['String']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  parent_merchant_uid?: Maybe<Scalars['String']['output']>;
  submitted_onboarding?: Maybe<Scalars['Boolean']['output']>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  ach_active?: Maybe<Scalars['Boolean']['output']>;
  api_key?: Maybe<Scalars['String']['output']>;
  card_active?: Maybe<Scalars['Boolean']['output']>;
  cash_active?: Maybe<Scalars['Boolean']['output']>;
  fee_model?: Maybe<FeeModel>;
  is_system?: Maybe<Scalars['Boolean']['output']>;
  merchant_name?: Maybe<Scalars['String']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  parent_merchant_uid?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<MerchantSettings>;
  submitted_onboarding?: Maybe<Scalars['Boolean']['output']>;
};

export type MerchantSettings = {
  __typename?: 'MerchantSettings';
  contact_email?: Maybe<Scalars['AWSEmail']['output']>;
  contact_phone?: Maybe<Scalars['AWSPhone']['output']>;
  facebook?: Maybe<Scalars['AWSURL']['output']>;
  instagram?: Maybe<Scalars['AWSURL']['output']>;
  linkedin?: Maybe<Scalars['AWSURL']['output']>;
  tiktok?: Maybe<Scalars['AWSURL']['output']>;
  twitter?: Maybe<Scalars['AWSURL']['output']>;
  website?: Maybe<Scalars['AWSURL']['output']>;
};

export type MerchantSettingsInput = {
  contact_email?: InputMaybe<Scalars['AWSEmail']['input']>;
  contact_phone?: InputMaybe<Scalars['AWSPhone']['input']>;
  facebook?: InputMaybe<Scalars['AWSURL']['input']>;
  instagram?: InputMaybe<Scalars['AWSURL']['input']>;
  linkedin?: InputMaybe<Scalars['AWSURL']['input']>;
  tiktok?: InputMaybe<Scalars['AWSURL']['input']>;
  twitter?: InputMaybe<Scalars['AWSURL']['input']>;
  website?: InputMaybe<Scalars['AWSURL']['input']>;
};

export type MerchantUserInput = {
  email: Scalars['AWSEmail']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['AWSPhone']['input']>;
};

export type Merchants = {
  __typename?: 'Merchants';
  items?: Maybe<Array<Maybe<ListMerchant>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  id?: Maybe<Scalars['String']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  metadata_associate?: Maybe<MetadataAssociate>;
  metadata_associate_id?: Maybe<Scalars['String']['output']>;
  metadata_key?: Maybe<Scalars['String']['output']>;
  metadata_type?: Maybe<MetadataType>;
  metadata_value?: Maybe<Scalars['String']['output']>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum MetadataAssociate {
  Authorization = 'AUTHORIZATION',
  Invoice = 'INVOICE',
  PaymentIntent = 'PAYMENT_INTENT',
  PaymentMethodToken = 'PAYMENT_METHOD_TOKEN',
  PaymentSession = 'PAYMENT_SESSION',
  Recurring = 'RECURRING',
  Transaction = 'TRANSACTION'
}

export type MetadataCore = {
  __typename?: 'MetadataCore';
  metadata: Scalars['AWSJSON']['output'];
};

export enum MetadataType {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Int = 'INT',
  String = 'STRING'
}

export type MissedRecurringPaymentData = {
  __typename?: 'MissedRecurringPaymentData';
  fee?: Maybe<Scalars['Int']['output']>;
  number_of_payments_missed?: Maybe<Scalars['Int']['output']>;
  total_amount_owed?: Maybe<Scalars['Int']['output']>;
};

export enum ModelMutation {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type ModelMutationMap = {
  create?: InputMaybe<Scalars['String']['input']>;
  delete?: InputMaybe<Scalars['String']['input']>;
  update?: InputMaybe<Scalars['String']['input']>;
};

export enum ModelOperation {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update'
}

export enum ModelQuery {
  Get = 'get',
  List = 'list'
}

export type ModelQueryMap = {
  get?: InputMaybe<Scalars['String']['input']>;
  list?: InputMaybe<Scalars['String']['input']>;
};

export enum ModelSubscriptionLevel {
  Off = 'off',
  On = 'on',
  Public = 'public'
}

export type ModelSubscriptionMap = {
  level?: InputMaybe<ModelSubscriptionLevel>;
  onCreate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onDelete?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onUpdate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum MoveDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelRecurringPayment?: Maybe<Scalars['Boolean']['output']>;
  createAuthorization: Authorization;
  createCapture: Transaction;
  createInvoice?: Maybe<Invoice>;
  createInvoiceEmail?: Maybe<Scalars['Boolean']['output']>;
  createMerchant?: Maybe<ListMerchant>;
  createOfflineTransaction?: Maybe<OfflineTransaction>;
  createOneTimePayment?: Maybe<OneTimePayment>;
  createPaymentLink?: Maybe<PaymentLink>;
  createPaymentMethodToken?: Maybe<PaymentMethodToken>;
  createPayor?: Maybe<Payor>;
  createReceiptEmail?: Maybe<Scalars['Boolean']['output']>;
  createRecurringPayment?: Maybe<RecurringPayment>;
  createRefund?: Maybe<Scalars['Boolean']['output']>;
  createRetryForFailedRecurringPayment?: Maybe<Scalars['Boolean']['output']>;
  createUser?: Maybe<User>;
  createUserPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  createVoidForRefund?: Maybe<Scalars['Boolean']['output']>;
  deleteInvoice?: Maybe<Scalars['Boolean']['output']>;
  deleteMetadata?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  updateDevice?: Maybe<Scalars['Boolean']['output']>;
  updateInvoice?: Maybe<Scalars['Boolean']['output']>;
  updateMerchantSettings?: Maybe<Scalars['Boolean']['output']>;
  updateMetadata?: Maybe<Scalars['Boolean']['output']>;
  updatePaymentLink?: Maybe<PaymentLink>;
  updatePayor?: Maybe<Scalars['Boolean']['output']>;
  updateRecurringPayment?: Maybe<RecurringPayment>;
};


export type MutationCancelRecurringPaymentArgs = {
  recurring_id: Scalars['String']['input'];
};


export type MutationCreateAuthorizationArgs = {
  account_code?: InputMaybe<Scalars['String']['input']>;
  additional_purchase_data?: InputMaybe<AdditionalPurchaseDataInput>;
  amount: Scalars['Int']['input'];
  digital_wallet?: InputMaybe<DigitalWalletInput>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  fee_mode?: InputMaybe<FeeMode>;
  health_expense_type?: InputMaybe<HealthExpenseType>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  merchant_uid: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['AWSJSON']['input']>;
  payment_method?: InputMaybe<PaymentMethodInput>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sale_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCaptureArgs = {
  allow_exceeded_amount?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reauth?: InputMaybe<Scalars['Boolean']['input']>;
  amount: Scalars['Int']['input'];
  authorization_id: Scalars['String']['input'];
  merchant_uid: Scalars['String']['input'];
  receipt_description?: InputMaybe<Scalars['String']['input']>;
  send_receipt?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationCreateInvoiceEmailArgs = {
  invoice_id: Scalars['String']['input'];
};


export type MutationCreateMerchantArgs = {
  merchant_name: Scalars['String']['input'];
  parent_merchant_uid?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<MerchantUserInput>;
};


export type MutationCreateOfflineTransactionArgs = {
  input: OfflineTransactionInput;
};


export type MutationCreateOneTimePaymentArgs = {
  account_code?: InputMaybe<Scalars['String']['input']>;
  additional_purchase_data?: InputMaybe<AdditionalPurchaseDataInput>;
  amount: Scalars['Int']['input'];
  digital_wallet?: InputMaybe<DigitalWalletInput>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  fee_mode?: InputMaybe<FeeMode>;
  health_expense_type?: InputMaybe<HealthExpenseType>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  merchant_uid: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['AWSJSON']['input']>;
  payment_method?: InputMaybe<PaymentMethodInput>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  payment_parameters_name?: InputMaybe<Scalars['String']['input']>;
  receipt_description?: InputMaybe<Scalars['String']['input']>;
  recurring_id?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  send_receipt?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreatePaymentLinkArgs = {
  input: PaymentLinkInput;
};


export type MutationCreatePaymentMethodTokenArgs = {
  input: PaymentMethodTokenInput;
};


export type MutationCreatePayorArgs = {
  input: PayorInput;
};


export type MutationCreateReceiptEmailArgs = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  receipt_description?: InputMaybe<Scalars['String']['input']>;
  transaction_id: Scalars['String']['input'];
};


export type MutationCreateRecurringPaymentArgs = {
  input: RecurringPaymentInput;
};


export type MutationCreateRefundArgs = {
  amount: Scalars['Int']['input'];
  refund_email?: InputMaybe<Scalars['String']['input']>;
  refund_reason: RefundReasonInput;
  transaction_id: Scalars['String']['input'];
};


export type MutationCreateRetryForFailedRecurringPaymentArgs = {
  recurring_id: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserPasswordResetArgs = {
  user_pool: UserPool;
  username: Scalars['String']['input'];
};


export type MutationCreateVoidForRefundArgs = {
  transaction_id: Scalars['String']['input'];
};


export type MutationDeleteInvoiceArgs = {
  invoice_id: Scalars['String']['input'];
};


export type MutationDeleteMetadataArgs = {
  id: Scalars['String']['input'];
  merchant_uid: Scalars['String']['input'];
  metadata_associate: MetadataAssociate;
  metadata_keys: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationDeleteUserArgs = {
  user_pool: UserPool;
  username: Scalars['String']['input'];
};


export type MutationUpdateDeviceArgs = {
  device_description: Scalars['String']['input'];
  device_id: Scalars['String']['input'];
  merchant_uid: Scalars['String']['input'];
};


export type MutationUpdateInvoiceArgs = {
  invoice_id: Scalars['String']['input'];
  invoice_update_input: InvoiceUpdateInput;
};


export type MutationUpdateMerchantSettingsArgs = {
  merchant_uid: Scalars['ID']['input'];
  settings: MerchantSettingsInput;
};


export type MutationUpdateMetadataArgs = {
  id: Scalars['String']['input'];
  merchant_uid: Scalars['String']['input'];
  metadata: Scalars['AWSJSON']['input'];
  metadata_associate: MetadataAssociate;
};


export type MutationUpdatePaymentLinkArgs = {
  input: UpdatePaymentLinkInput;
};


export type MutationUpdatePayorArgs = {
  payor_data: PayorData;
  payor_id: Scalars['String']['input'];
};


export type MutationUpdateRecurringPaymentArgs = {
  input: UpdateRecurringPaymentInput;
};

export type OfflineTransaction = {
  __typename?: 'OfflineTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  instance_id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  transaction_date?: Maybe<Scalars['AWSDate']['output']>;
  type?: Maybe<OfflineTransactionType>;
};

export type OfflineTransactionInput = {
  amount: Scalars['Int']['input'];
  invoice_id: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  transaction_date: Scalars['AWSDate']['input'];
  type: OfflineTransactionType;
};

export enum OfflineTransactionType {
  Ach = 'ACH',
  Card = 'CARD',
  Cash = 'CASH',
  Other = 'OTHER'
}

export type OneTimePayment = {
  __typename?: 'OneTimePayment';
  amount?: Maybe<Scalars['Int']['output']>;
  card_brand?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['AWSDateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  last_four?: Maybe<Scalars['String']['output']>;
  service_fee?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TransactionStatus>;
  transaction_id?: Maybe<Scalars['String']['output']>;
};

export enum Operator {
  Equal = 'EQUAL',
  EqualFalse = 'EQUAL_FALSE',
  EqualTrue = 'EQUAL_TRUE',
  GreaterEqual = 'GREATER_EQUAL',
  GreaterThan = 'GREATER_THAN',
  InList = 'IN_LIST',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessEqual = 'LESS_EQUAL',
  LessThan = 'LESS_THAN',
  Like = 'LIKE',
  NotEqual = 'NOT_EQUAL',
  NotInList = 'NOT_IN_LIST',
  NotLike = 'NOT_LIKE'
}

export type PaymentLink = {
  __typename?: 'PaymentLink';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethodsEnum>;
  account_code?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  amount_is_variable?: Maybe<Scalars['Boolean']['output']>;
  call_to_action?: Maybe<CallToActionType>;
  created_date?: Maybe<Scalars['AWSDateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  custom_success_message?: Maybe<Scalars['String']['output']>;
  fee_mode?: Maybe<FeeMode>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  link_id?: Maybe<Scalars['String']['output']>;
  link_name?: Maybe<Scalars['String']['output']>;
  link_url?: Maybe<Scalars['String']['output']>;
  max_amount?: Maybe<Scalars['Int']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  min_amount?: Maybe<Scalars['Int']['output']>;
  payment_description?: Maybe<Scalars['String']['output']>;
  payment_name?: Maybe<Scalars['String']['output']>;
  redirect_url?: Maybe<Scalars['String']['output']>;
  require_phone?: Maybe<Scalars['Boolean']['output']>;
};

export type PaymentLinkInput = {
  accepted_payment_methods?: InputMaybe<AcceptedPaymentMethodsEnum>;
  account_code?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  amount_is_variable?: InputMaybe<Scalars['Boolean']['input']>;
  call_to_action?: InputMaybe<CallToActionType>;
  currency?: InputMaybe<Scalars['String']['input']>;
  custom_success_message?: InputMaybe<Scalars['String']['input']>;
  fee_mode?: InputMaybe<FeeMode>;
  link_name: Scalars['String']['input'];
  max_amount?: InputMaybe<Scalars['Int']['input']>;
  merchant_uid: Scalars['String']['input'];
  min_amount?: InputMaybe<Scalars['Int']['input']>;
  payment_description?: InputMaybe<Scalars['String']['input']>;
  payment_name: Scalars['String']['input'];
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  require_phone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentLinks = {
  __typename?: 'PaymentLinks';
  items?: Maybe<Array<Maybe<PaymentLink>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export type PaymentMethodInput = {
  ach?: InputMaybe<AchInput>;
  card?: InputMaybe<CardInput>;
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMethodToken = {
  __typename?: 'PaymentMethodToken';
  address_line1?: Maybe<Scalars['String']['output']>;
  address_line2?: Maybe<Scalars['String']['output']>;
  card_brand?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  exp_date?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  last_four?: Maybe<Scalars['String']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  payment_method_id?: Maybe<Scalars['String']['output']>;
  payment_type?: Maybe<PaymentType>;
  payor?: Maybe<Payor>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  wallet_type?: Maybe<WalletType>;
};


export type PaymentMethodTokenPayorArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export type PaymentMethodTokenInput = {
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  card_brand?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  exp_date?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_four: Scalars['String']['input'];
  merchant_uid?: InputMaybe<Scalars['String']['input']>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  payment_type: PaymentType;
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  processor: Scalars['String']['input'];
  processor_token: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  wallet_type?: InputMaybe<WalletType>;
};

export type PaymentMethodTokensWithQuery = {
  __typename?: 'PaymentMethodTokensWithQuery';
  items?: Maybe<Array<Maybe<PaymentMethodToken>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export enum PaymentType {
  Ach = 'ACH',
  Card = 'CARD',
  Cash = 'CASH'
}

export type Payor = {
  __typename?: 'Payor';
  address_line1?: Maybe<Scalars['String']['output']>;
  address_line2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  payor_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type PayorData = {
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type PayorInput = {
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  merchant_uid?: InputMaybe<Scalars['String']['input']>;
  payor_id?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type Payors = {
  __typename?: 'Payors';
  items?: Maybe<Array<Maybe<Payor>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  authorizations?: Maybe<Authorizations>;
  devices?: Maybe<Devices>;
  disputes?: Maybe<Disputes>;
  errorText: Array<Maybe<Scalars['String']['output']>>;
  invoices?: Maybe<InvoicesWithQuery>;
  merchant?: Maybe<Merchant>;
  merchants?: Maybe<Merchants>;
  missedRecurringPaymentData?: Maybe<MissedRecurringPaymentData>;
  paymentLinks?: Maybe<PaymentLinks>;
  paymentMethodTokens?: Maybe<PaymentMethodTokensWithQuery>;
  payors?: Maybe<Payors>;
  recurringPayments?: Maybe<RecurringPaymentsWithQuery>;
  serviceFeeAmount?: Maybe<ServiceFeeAmount>;
  settlements?: Maybe<Settlements>;
  transactions?: Maybe<Transactions>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAuthorizationsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryDevicesArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryDisputesArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryErrorTextArgs = {
  error_codes: Array<InputMaybe<Scalars['String']['input']>>;
  language_code: Scalars['String']['input'];
};


export type QueryInvoicesArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryMerchantArgs = {
  merchant_name?: InputMaybe<Scalars['String']['input']>;
  merchant_uid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMerchantsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryMissedRecurringPaymentDataArgs = {
  recurring_id: Scalars['String']['input'];
};


export type QueryPaymentLinksArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryPaymentMethodTokensArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryPayorsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryRecurringPaymentsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryServiceFeeAmountArgs = {
  amount: Scalars['Int']['input'];
  merchant_uid: Scalars['String']['input'];
};


export type QuerySettlementsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryTransactionsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  offset_id?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryUsersArgs = {
  merchant_uid?: InputMaybe<Scalars['String']['input']>;
  user_pool: UserPool;
};

export type QueryPair = {
  conjunctive_operator?: InputMaybe<ConjunctiveOperator>;
  in_values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<Operator>;
  query_group?: InputMaybe<Array<InputMaybe<QueryPair>>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum RecurringInterval {
  Annual = 'ANNUAL',
  BiAnnual = 'BI_ANNUAL',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY'
}

export type RecurringPayment = {
  __typename?: 'RecurringPayment';
  account_code?: Maybe<Scalars['String']['output']>;
  amount_per_payment?: Maybe<Scalars['Int']['output']>;
  created_date?: Maybe<Scalars['AWSDateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fee_mode?: Maybe<FeeMode>;
  fee_per_payment?: Maybe<Scalars['Int']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_processing?: Maybe<Scalars['Boolean']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['AWSJSON']['output']>;
  mute_all_emails?: Maybe<Scalars['Boolean']['output']>;
  next_payment_date?: Maybe<Scalars['AWSDate']['output']>;
  payment_interval?: Maybe<RecurringInterval>;
  payment_method?: Maybe<PaymentMethodToken>;
  payor?: Maybe<Payor>;
  prev_payment_date?: Maybe<Scalars['AWSDate']['output']>;
  recurring_description?: Maybe<Scalars['String']['output']>;
  recurring_id?: Maybe<Scalars['String']['output']>;
  recurring_name?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  remaining_payments?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<RecurringStatus>;
  total_amount_per_payment?: Maybe<Scalars['Int']['output']>;
};


export type RecurringPaymentMetadataArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type RecurringPaymentPayment_MethodArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type RecurringPaymentPayorArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export type RecurringPaymentInput = {
  account_code?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Int']['input'];
  fee_mode?: InputMaybe<FeeMode>;
  first_payment_date?: InputMaybe<Scalars['AWSDate']['input']>;
  merchant_uid: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['AWSJSON']['input']>;
  mute_all_emails?: InputMaybe<Scalars['Boolean']['input']>;
  payment_count?: InputMaybe<Scalars['Int']['input']>;
  payment_interval: RecurringInterval;
  payment_method_id: Scalars['String']['input'];
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']['input']>;
  recurring_description?: InputMaybe<Scalars['String']['input']>;
  recurring_name: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type RecurringPaymentsWithQuery = {
  __typename?: 'RecurringPaymentsWithQuery';
  items?: Maybe<Array<Maybe<RecurringPayment>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export enum RecurringStatus {
  InstrumentFailure = 'INSTRUMENT_FAILURE',
  Success = 'SUCCESS',
  SystemFailure = 'SYSTEM_FAILURE'
}

export type RefundReason = {
  __typename?: 'RefundReason';
  reason_code?: Maybe<RefundReasonCode>;
  reason_details?: Maybe<Scalars['String']['output']>;
};

export enum RefundReasonCode {
  Duplicate = 'DUPLICATE',
  Fraudulent = 'FRAUDULENT',
  Other = 'OTHER',
  RequestedByCustomer = 'REQUESTED_BY_CUSTOMER'
}

export type RefundReasonInput = {
  reason_code: RefundReasonCode;
  reason_details?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceFeeAmount = {
  __typename?: 'ServiceFeeAmount';
  ach?: Maybe<ServiceFeeCalculation>;
  card?: Maybe<ServiceFeeCalculation>;
};

export type ServiceFeeCalculation = {
  __typename?: 'ServiceFeeCalculation';
  adjusted_total?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  fee_limit_reached?: Maybe<Scalars['Boolean']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Settlement = {
  __typename?: 'Settlement';
  currency?: Maybe<Scalars['String']['output']>;
  gross_amount?: Maybe<Scalars['Int']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  net_amount?: Maybe<Scalars['Int']['output']>;
  settlement_batch?: Maybe<Scalars['Int']['output']>;
  settlement_date?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  total_adjustments?: Maybe<Scalars['Int']['output']>;
  total_fees?: Maybe<Scalars['Int']['output']>;
  transaction_debit_count?: Maybe<Scalars['Int']['output']>;
  transaction_dispute_count?: Maybe<Scalars['Int']['output']>;
  transaction_reversal_count?: Maybe<Scalars['Int']['output']>;
  transfer_date?: Maybe<Scalars['AWSDateTime']['output']>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum SettlementStatus {
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type Settlements = {
  __typename?: 'Settlements';
  items?: Maybe<Array<Maybe<Settlement>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortPair = {
  direction?: InputMaybe<SortDirection>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type SqlQuery = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
  sort_list?: InputMaybe<Array<InputMaybe<SortPair>>>;
};

export enum TaxIndicatorType {
  NotTaxable = 'NOT_TAXABLE',
  NoTaxInfoProvided = 'NO_TAX_INFO_PROVIDED',
  TaxAmountProvided = 'TAX_AMOUNT_PROVIDED'
}

export enum TaxType {
  CitySales = 'CITY_SALES',
  Energy = 'ENERGY',
  Gst = 'GST',
  LocalSales = 'LOCAL_SALES',
  MunicipalSales = 'MUNICIPAL_SALES',
  NationalSales = 'NATIONAL_SALES',
  NotSupported = 'NOT_SUPPORTED',
  Occupancy = 'OCCUPANCY',
  Other = 'OTHER',
  Pst = 'PST',
  Room = 'ROOM',
  StateSales = 'STATE_SALES',
  Unknown = 'UNKNOWN',
  Vat = 'VAT'
}

export type TimestampConfiguration = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  account_code?: Maybe<Scalars['String']['output']>;
  ach_return_details?: Maybe<AchReturnDetails>;
  additional_purchase_data?: Maybe<AdditionalPurchaseData>;
  authorization_id?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  device_id?: Maybe<Scalars['String']['output']>;
  dispute_status?: Maybe<DisputeStatus>;
  failure_reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fee_mode?: Maybe<FeeMode>;
  fees?: Maybe<Scalars['Int']['output']>;
  gross_amount?: Maybe<Scalars['Int']['output']>;
  invoice?: Maybe<Invoice>;
  is_settled?: Maybe<Scalars['Boolean']['output']>;
  merchant_uid?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['AWSJSON']['output']>;
  net_amount?: Maybe<Scalars['Int']['output']>;
  payment_method?: Maybe<PaymentMethodToken>;
  recurring?: Maybe<RecurringPayment>;
  reference?: Maybe<Scalars['String']['output']>;
  refund_reason?: Maybe<RefundReason>;
  refund_voidable?: Maybe<Scalars['Boolean']['output']>;
  refunded_amount?: Maybe<Scalars['Int']['output']>;
  sale_id?: Maybe<Scalars['String']['output']>;
  settlement_batch?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TransactionStatus>;
  timezone?: Maybe<Scalars['String']['output']>;
  transaction_date?: Maybe<Scalars['AWSDateTime']['output']>;
  transaction_id?: Maybe<Scalars['String']['output']>;
  transaction_type?: Maybe<TransactionType>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']['output']>;
};


export type TransactionMetadataArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type TransactionPayment_MethodArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Returned = 'RETURNED',
  Settled = 'SETTLED',
  Succeeded = 'SUCCEEDED',
  Voided = 'VOIDED'
}

export enum TransactionType {
  AchReturn = 'ACH_RETURN',
  Debit = 'DEBIT',
  Failure = 'FAILURE',
  Reversal = 'REVERSAL'
}

export type Transactions = {
  __typename?: 'Transactions';
  items?: Maybe<Array<Maybe<Transaction>>>;
  total_row_count?: Maybe<Scalars['Int']['output']>;
};

export type UpdatePaymentLinkInput = {
  accepted_payment_methods?: InputMaybe<AcceptedPaymentMethodsEnum>;
  account_code?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  amount_is_variable?: InputMaybe<Scalars['Boolean']['input']>;
  call_to_action?: InputMaybe<CallToActionType>;
  currency?: InputMaybe<Scalars['String']['input']>;
  custom_success_message?: InputMaybe<Scalars['String']['input']>;
  fee_mode?: InputMaybe<FeeMode>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  link_id: Scalars['String']['input'];
  link_name?: InputMaybe<Scalars['String']['input']>;
  max_amount?: InputMaybe<Scalars['Int']['input']>;
  merchant_uid: Scalars['String']['input'];
  min_amount?: InputMaybe<Scalars['Int']['input']>;
  payment_description?: InputMaybe<Scalars['String']['input']>;
  payment_name?: InputMaybe<Scalars['String']['input']>;
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  require_phone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRecurringPaymentInput = {
  mute_all_emails?: InputMaybe<Scalars['Boolean']['input']>;
  pay_all_missed_payments?: InputMaybe<Scalars['Boolean']['input']>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  recurring_id: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  user_status?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  email: Scalars['AWSEmail']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  merchant_uid?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['AWSPhone']['input']>;
  user_pool: UserPool;
};

export enum UserPool {
  Merchant = 'MERCHANT',
  Partner = 'PARTNER',
  System = 'SYSTEM'
}

export enum WalletType {
  ApplePay = 'APPLE_PAY',
  ClickToPay = 'CLICK_TO_PAY',
  GooglePay = 'GOOGLE_PAY',
  SamsungPay = 'SAMSUNG_PAY',
  VisaStaged = 'VISA_STAGED'
}
